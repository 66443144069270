<template>
	<div :class="layoutContainerClass" @click="onDocumentClick">
		<div v-if="role_user != 'superadmin'" class="layout-main">
			<AppTopBar :items="menu" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive" :topbarMenuActive="topbarMenuActive" :activeInlineProfile="activeInlineProfile" 
				@topbar-item-click="onTopbarItemClick" @menubutton-click="onMenuButtonClick" @sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" 
				@menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

			<AppMenu :model="menu" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic" :pinActive="pinActive" :activeInlineProfile="activeInlineProfile"
				@sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

			<AppBreadcrumb :menuMode="menuMode" v-model:searchActive="searchActive" v-model:searchClick="searchClick" @menubutton-click="onMenuButtonClick" @rightmenu-button-click="onRightMenuButtonClick" />

			<div class="layout-main-content">
				<router-view />
			</div>

			<AppFooter :colorScheme="colorScheme" />
		</div>

		<div v-else class="layout-main">
			<AppTopBar :items="menuSuper" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive" :topbarMenuActive="topbarMenuActive" :activeInlineProfile="activeInlineProfile" 
				@topbar-item-click="onTopbarItemClick" @menubutton-click="onMenuButtonClick" @sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" 
				@menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

			<AppMenu :model="menuSuper" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic" :pinActive="pinActive" :activeInlineProfile="activeInlineProfile"
				@sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

			<AppBreadcrumb :menuMode="menuMode" v-model:searchActive="searchActive" v-model:searchClick="searchClick" @menubutton-click="onMenuButtonClick" @rightmenu-button-click="onRightMenuButtonClick" />

			<div class="layout-main-content">
				<router-view />
			</div>

			<AppFooter :colorScheme="colorScheme" />
		</div>

		<AppRightMenu :rightMenuActive="rightMenuActive" @rightmenu-click="onRightMenuClick" @rightmenu-active="onChangeRightMenuActive" />

		<AppConfig v-model:menuMode="menuMode" :theme="theme" :componentTheme="componentTheme" :colorScheme="colorScheme" :configActive="configActive" :configClick="configClick"
			@config-button-click="onConfigButtonClick" @config-click="onConfigClick" @change-color-scheme="onChangeColorScheme" @change-component-theme="onChangeComponentTheme" @change-menu-theme="onChangeMenuTheme"
			@change-menu-mode="onChangeMenuMode" />

		<Toast ref="toast3">
            <template #message="slotProps">
                <div class="custom-toast">
                    
                    <div class="toast-content">
                        <h4>{{ slotProps.message.summary }}</h4>
                        <Image v-if="slotProps.message.data?.tampil" class="ml-4" :src="src" :width="120" style="width:100%"/>
                        <p>{{ slotProps.message.detail }}</p>
                    </div>
                </div>
            </template>
        </Toast>
	</div>
</template>

<script>
import { ref } from "vue";
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
// import AppRightMenu from './AppRightMenu.vue';
import AppFooter from './AppFooter.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppConfig from './AppConfig.vue';
import EventBus from './event-bus';
import { getToken, onMessage } from "firebase/messaging";

import store from "@/store/store.js";
import messaging from "./components/getMessage";

const getFcmRequest = ref(true);

// const getFcmToken = async () => {
// 	getToken(messaging)
// 		.then((currentToken) => {
// 			console.log('masuk');
			
// 			if (currentToken) {
// 				store.commit('setfcmtoken', currentToken)
// 			} else {
// 				// Show permission request UI
// 				console.log("No registration token available. Request permission to generate one.");
// 			}
// 		})
// 		.catch((err) => {
// 			console.log("An error occurred while retrieving token. ", err);
// 			setTimeout(() => {
// 				this.$toast.add({ severity: 'danger', summary: 'Konfirmasi', detail: err.message, life: 3000 })
// 			}, 1000);
// 			throw err;
// 		});
// }
// onMounted(() => {
// 		// FOR CHROME or FIREFOX
// 		getFcmToken();
// 		console.log('mounted');
		
// 		// FOR SAFARI MUST BE TRIGGERED BY USER INTERACTION
// 		const bodyEl = document.querySelector("body");
// 		bodyEl.addEventListener("click", async () => {
// 			if (getFcmRequest.value) {
// 				let permission = await Notification.requestPermission();
// 				console.log('permission', permission);
// 				if (permission === "granted") {
// 					console.log('Notification permission granted.');
// 					getFcmToken();
// 					getFcmRequest.value = false;
// 				}
// 			}
// 		});
// 	});

export default {
	emits: ['layout-mode-change', 'menu-theme', 'layoutModeChange', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'change-color-scheme', 'change-component-theme', 'change-menu-theme', 'change-inlinemenu'],
	props: {
		colorScheme: String,
		topbarTheme: String,
		theme: String,
		componentTheme: String
		
	},
    data() {
        return {
			search: false,
			searchClick: false,
			searchActive: false,
			menuMode: 'sidebar',
			menuActive: false,
			activeInlineProfile: false,
			inlineMenuClick: false,
			overlayMenuActive: false,
			topbarMenuActive: false,
			topbarItemClick: false,
			sidebarActive: false,
			sidebarStatic: false,
			pinActive: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			rightMenuClick: false,
			rightMenuActive: false,
			configActive: false,
			configClick: false,
            menu: [
				{
                    label: 'Dashboard',
                    icon: 'pi pi-fw pi-home',
                    to: '/dashboard',
                },
                {
                    label: 'Master',
                    icon: 'pi pi-fw pi-id-card',
                    // to: '/master',
					items: [
						// { label: 'Bundle', icon: 'pi pi-fw pi-id-card', to: '/master/bundle' },
                        // { label: 'Grade PT', icon: 'pi pi-fw pi-id-card', to: '/master/grade_pt' },
						// { label: 'GX Class', icon: 'pi pi-fw pi-id-card', to: '/master/gx_class' },
						// { label: 'Membership', icon: 'pi pi-fw pi-id-card', to: '/master/membership' },
						// // { label: 'Membership Club', icon: 'pi pi-fw pi-id-card', to: '/club_membership' },
						// { label: 'Broadcast', icon: 'pi pi-fw pi-id-card', to: '/master/broadcast' },
						// { label: 'Club', icon: 'pi pi-fw pi-id-card', to: '/master/club' },
						// { label: 'News', icon: 'pi pi-fw pi-id-card', to: '/master/news' },
						// { label: 'Parameter', icon: 'pi pi-fw pi-id-card', to: '/master/parameter' },
						// { label: 'Product', icon: 'pi pi-fw pi-id-card', to: '/master/product' },
						// { label: 'Promo Banner', icon: 'pi pi-fw pi-id-card', to: '/master/banner' },
						// { label: 'PT / Instructor', icon: 'pi pi-fw pi-id-card', to: '/master/pt' },
						// { label: 'PT Session', icon: 'pi pi-fw pi-id-card', to: '/master/sesi_pt' },
						// { label: 'Sales', icon: 'pi pi-fw pi-id-card', to: '/master/sales' },
                        // { label: 'Studio', icon: 'pi pi-fw pi-id-card', to: '/master/studio' },
                        { label: 'User', icon: 'pi pi-fw pi-id-card', to: '/master/user' },
                    ],
                },
				// {
                //     label: 'GX Schedule',
                //     icon: 'pi pi-fw pi-calendar',
                //     // to: '/master',
				// 	items: [
				// 		{
				// 			label: 'GX Schedule Setting',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/gx_schedule',
				// 		},
				// 		{
				// 			label: 'GX Schedule Finalization',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/gx_schedule_finalization',
				// 		},
                //     ],
                // },
				// {
                //     label: 'Club',
                //     icon: 'pi pi-fw pi-building',
                //     to: '/club',
                // },
				// {
                //     label: 'Cutting Session PT',
                //     icon: 'pi pi-fw pi-calendar',
                //     to: '/cutting_sesi_pt',
                // },
				{
                    label: 'Daily Check In',
                    icon: 'pi pi-fw pi-calendar',
                    to: '/daily_check_in',
                },
				{
                    label: 'Report',
                    icon: 'pi pi-fw pi-file',
                    // to: '/master',
					items: [
						{ label: 'Towel', icon: 'pi pi-fw pi-file', to: '/report/towel' },
                    ],
                },
				// {
                //     label: 'Daily GX Class',
                //     icon: 'pi pi-fw pi-calendar',
                //     to: '/gx_class_schedule',
                // },
				// {
                //     label: 'History',
                //     icon: 'pi pi-fw pi-calendar',
                //     // to: '/master',
				// 	items: [
				// 		{
				// 			label: 'History Check In',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/history_check_in',
				// 		},
				// 		{
				// 			label: 'History Cutting Session PT',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/history_cutting_session',
				// 		},
                //     ],
                // },
				// {
                //     label: 'Membership',
                //     icon: 'pi pi-fw pi-user',
                //     to: '/membership',
                // },
				// {
                //     label: 'PT Absence Request',
                //     icon: 'pi pi-fw pi-user',
                //     to: '/pt_absence',
                // },
				// {
                //     label: 'Transaction',
                //     icon: 'pi pi-fw pi-money-bill',
                //     to: '/transaction',
                // },
            ],
			menuSuper: [
				{
                    label: 'Dashboard',
                    icon: 'pi pi-fw pi-home',
                    to: '/dashboard',
                },
                {
                    label: 'Master',
                    icon: 'pi pi-fw pi-id-card',
                    // to: '/master',
					items: [
						// { label: 'Bundle', icon: 'pi pi-fw pi-id-card', to: '/master/bundle' },
                        // { label: 'Grade PT', icon: 'pi pi-fw pi-id-card', to: '/master/grade_pt' },
						// { label: 'GX Class', icon: 'pi pi-fw pi-id-card', to: '/master/gx_class' },
						// { label: 'Membership', icon: 'pi pi-fw pi-id-card', to: '/master/membership' },
						// { label: 'Barang', icon: 'pi pi-fw pi-id-card', to: '/master/barang' },
						{ label: 'Broadcast', icon: 'pi pi-fw pi-id-card', to: '/master/broadcast' },
						{ label: 'Club', icon: 'pi pi-fw pi-id-card', to: '/master/club' },
						{ label: 'News', icon: 'pi pi-fw pi-id-card', to: '/master/news' },
						// { label: 'Parameter', icon: 'pi pi-fw pi-id-card', to: '/master/parameter' },
						{ label: 'Product', icon: 'pi pi-fw pi-id-card', to: '/master/barang' },
						{ label: 'Promo Banner', icon: 'pi pi-fw pi-id-card', to: '/master/banner' },
						
						// { label: 'PT / Instructor', icon: 'pi pi-fw pi-id-card', to: '/master/pt' },
						// { label: 'PT Session', icon: 'pi pi-fw pi-id-card', to: '/master/sesi_pt' },
						// { label: 'Sales', icon: 'pi pi-fw pi-id-card', to: '/master/sales' },
                        // { label: 'Studio', icon: 'pi pi-fw pi-id-card', to: '/master/studio' },
						{ label: 'User', icon: 'pi pi-fw pi-id-card', to: '/master/user' },
                        { label: 'Admin', icon: 'pi pi-fw pi-id-card', to: '/master/admin' },
                    ],
                },
				// {
                //     label: 'GX Schedule',
                //     icon: 'pi pi-fw pi-calendar',
                //     // to: '/master',
				// 	items: [
				// 		{
				// 			label: 'GX Schedule Setting',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/gx_schedule',
				// 		},
				// 		{
				// 			label: 'GX Schedule Finalization',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/gx_schedule_finalization',
				// 		},
                //     ],
                // },
				// {
                //     label: 'Club',
                //     icon: 'pi pi-fw pi-building',
                //     to: '/club',
                // },
				// {
                //     label: 'Cutting Session PT',
                //     icon: 'pi pi-fw pi-calendar',
                //     to: '/cutting_sesi_pt',
                // },
				{
                    label: 'Daily Check Inss',
                    icon: 'pi pi-fw pi-calendar',
                    to: '/daily_check_in',
                },
				{
                    label: 'Report',
                    icon: 'pi pi-fw pi-id-card',
                    // to: '/master',
					items: [
						{ label: 'Towel', icon: 'pi pi-fw pi-id-card', to: '/report/towel' },
                    ],
                },
				// {
                //     label: 'Daily GX Class',
                //     icon: 'pi pi-fw pi-calendar',
                //     to: '/gx_class_schedule',
                // },
				// {
                //     label: 'History',
                //     icon: 'pi pi-fw pi-calendar',
                //     // to: '/master',
				// 	items: [
				// 		{
				// 			label: 'History Check In',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/history_check_in',
				// 		},
				// 		{
				// 			label: 'History Cutting Session PT',
				// 			icon: 'pi pi-fw pi-calendar',
				// 			to: '/history_cutting_session',
				// 		},
                //     ],
                // },
				// {
                //     label: 'Membership',
                //     icon: 'pi pi-fw pi-user',
                //     to: '/membership',
                // },
				// {
                //     label: 'PT Absence Request',
                //     icon: 'pi pi-fw pi-user',
                //     to: '/pt_absence',
                // },
				// {
                //     label: 'Transaction',
                //     icon: 'pi pi-fw pi-money-bill',
                //     to: '/transaction',
                // },
            ],
			tanggal: null,
			club: null,
			refreshan: false,
			daily: [],
			src: null,
			data_user: null,
        }
    },
	mounted(){
		// FOR CHROME or FIREFOX
		this.getFcmToken();	
		this.getMessage()
		// this.get_daily()
		// FOR SAFARI MUST BE TRIGGERED BY USER INTERACTION
		const bodyEl = document.querySelector("body");
		bodyEl.addEventListener("click", async () => {
			if (getFcmRequest.value) {
				let permission = await Notification.requestPermission();
				// console.log('permission', permission);
				if (permission === "granted") {
					// console.log('Notification permission granted.');
					this.getFcmToken();
					getFcmRequest.value = false;
				}
			}
		});
	},
    watch: {
        $route() {
			this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
		async getMessage(){
			onMessage(messaging, async (payload) => {
				console.log(payload, 'ini payload');
				if(payload.data?.asd != 'refresh'){
					this.$toast.add({ severity: 'warn', summary: payload.notification.title, detail: payload.notification.body });
				}else{
					this.refreshan = true
					
					this.get_daily()
				}
				this.$store.commit('set_tipe_daily', payload.notification.title)
				this.$store.commit('set_user_daily', payload.notification.body)
				EventBus.emit('update-active-page', 'dashboard');
			
			});
		},
		async getFcmToken(){
			let vm = this
			
			getToken(messaging)
			.then((currentToken) => {
				// console.log('masuk');
				
				if (currentToken) {
					store.commit('setfcmtoken', currentToken)
					// console.log(store.state.user,'ini fcm token');
					vm.$axios.post('user/update_token_pc', {
						id: store.state.user.id,
						token_pc: store.state.fcm_token,
						// username: store.state.user.username,
					})
					.then((res) => {
						// console.log(res);
						if (res.data.status == 204) {
							console.log(res);
							
						}
					})
					.catch((err) => {
						console.log(err);
						
					})
				} else {
					// Show permission request UI
					console.log("No registration token available. Request permission to generate one.");
				}
			})
			.catch((err) => {
				console.log("An error occurred while retrieving token. ", err);
				setTimeout(() => {
					this.$toast.add({ severity: 'danger', summary: 'Konfirmasi', detail: err.message, life: 3000 })
				}, 1000);
				throw err;
			});
		},
		async tampil(){
            let vm = this
            if(vm.$store.state.tipe_daily == 'check_in'){
                // for (let i = 0; i < vm.daily.length; i++) {
                //     let x = vm.daily[i];
                //     if(x.ID == vm.$store.state.user_daily){
                //         vm.src = `${vm.$store.state.url}/${x.FOTO}`
                //         vm.nama_user = x.NAME
                //         break
                //     }
                // }
				let detail = await vm.$axios.post('/user/list', {
                    id : vm.$store.state.user_daily,
                });
                vm.data_user = detail.data.data[0]
                vm.src = `${vm.$store.state.url}/${vm.data_user.foto_user}`
                // vm.modalDaily = true
                
                this.$refs.toast3.add({ 
                    severity: 'success', 
                    summary: 'Check In', 
                    detail: `${vm.data_user.nama_user}`,
                    data: {
                        tampil: true, // URL gambar
                    },
                    life: 5000 });
                // this.refreshan = false
            }
                this.refreshan = false
                // console.log(vm.src, 'ini src');
        },
		async get_daily() {
            let vm = this;
            vm.loading1 = true;
            vm.club = vm.club ? vm.club : vm.club_id_admin
            // console.log(vm.club);
            // console.log(vm.$store.state.user);
            
            let tanggal = vm.tanggal ? vm.$moment(vm.tanggal).format('YYYYMMDD') : vm.$moment(new Date()).format('YYYYMMDD')
            // console.log(tanggal, 'ini tanggal');
            let daily = await vm.$axios.post('/absensi/cico', {
                kode_club: vm.club,
                tanggal: tanggal,
            });
            // console.log(daily.data.data, 'ini daily');
            vm.daily = daily.data.data.map((items, idx) => {
                // items.checkin = items.check_in ? vm.$moment(items.check_in).format("HH:mm") : "-"
                // items.checkout = items.check_out ? vm.$moment(items.check_out).format("HH:mm") : "-"
                // items.hand_towel = items.hand_towel ? items.hand_towel : 0
                // items.body_towel = items.body_towel ? items.body_towel : 0
                if(items.ID == 'DAILY VISIT'){
                    items.NAME = 'DAILY VISIT'
                }
                if(!items.STS_HTIN && !items.STS_HTOUT){
                    items.status_extra_hand_towel = 0
                }else if(!items.STS_HTIN && items.STS_HTOUT){
                    items.status_extra_hand_towel = 1
                }else {
                    items.status_extra_hand_towel = 2
                }
                if(!items.STS_BTIN && !items.STS_BTOUT){
                    items.status_extra_body_towel = 0
                }else if(!items.STS_BTIN && items.STS_BTOUT){
                    items.status_extra_body_towel = 1
                }else {
                    items.status_extra_body_towel = 2
                }
                return { ...items, no: idx + 1 };
            });
            // console.log(vm.daily);
            
            if(vm.refreshan){
                vm.tampil()
            }
        },
		onDocumentClick() {
			if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

			if(!this.topbarItemClick) {
				this.topbarMenuActive = false;
			}

			if(!this.menuClick) {
				if(this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					EventBus.emit('reset-active-index');
				}

				if (this.overlayMenuActive || this.staticMenuMobileActive) {
					this.overlayMenuActive = false;
					this.staticMenuMobileActive = false;
				}

				this.hideOverlayMenu();
				this.unblockBodyScroll();
			}

			if(!this.rightMenuClick) {
				this.rightMenuActive = false;
			}

			if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

			this.topbarItemClick = false;
			this.menuClick = false;
			this.configClick = false;
			this.rightMenuClick = false;
			this.searchClick = false;
			this.inlineMenuClick = false;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
		onSidebarMouseOver() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
				this.sidebarActive = this.isDesktop();
				setTimeout(() => {
					this.pinActive = this.isDesktop();
				}, 200);
			}
        },
        onSidebarMouseLeave() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
				setTimeout(() => {
					this.sidebarActive = false;
					this.pinActive = false;
				}, 250);
			}
        },
		hideOverlayMenu() {
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		onMenuButtonClick(event){
			this.menuClick = true;

			if (this.isOverlay()) {
				this.overlayMenuActive = !this.overlayMenuActive;
			}

			if (this.isDesktop()) {
				this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
			} else {
				this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarItemClick(event){
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onRightMenuButtonClick() {
			this.rightMenuClick = true;
			this.rightMenuActive = true;
		},
		onRightMenuClick(){
			this.rightMenuClick = true;
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onMenuItemClick(event) {
			if(!event.item.items) {
				this.hideOverlayMenu();
				EventBus.emit('reset-active-index');
			}

			if(!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}
		},
		onChangeMenuMode(menuMode) {
			this.menuMode = menuMode;
			this.overlayMenuActive = false;
		},
		onConfigClick() {
            this.configClick = true;
        },
		onConfigButtonClick(event) {
			this.configActive = !this.configActive;
			this.configClick = true;
			event.preventDefault();
		},
		onChangeRightMenuActive(active) {
			this.rightMenuActive = active;
		},
		onChangeMenuTheme(theme) {
            this.$emit('change-menu-theme', theme);
        },
		onChangeComponentTheme(theme) {
			this.$emit('change-component-theme', theme);
		},
		onChangeColorScheme(scheme) {
			this.$emit('change-color-scheme', scheme);
		},
		onToggleMenu(event) {
			this.menuClick = true;

			if (this.overlayMenuActive) {
				this.overlayMenuActive = false;
			}	

			if (this.sidebarActive) {
				this.sidebarStatic = !this.sidebarStatic;
			}	

			event.preventDefault();
		},
		onChangeActiveInlineMenu() {
			this.activeInlineProfile = !this.activeInlineProfile;
		},
		blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
		isHorizontal() {
			return this.menuMode === 'horizontal';
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		isOverlay() {
			return this.menuMode === 'overlay';
		},
		isStatic() {
			return this.menuMode === 'static';
		},
		isSidebar() {
			return this.menuMode === 'sidebar';
		},
		isDesktop() {
			return window.innerWidth > 991;
		},
		isMobile() {
			return window.innerWidth <= 991;
		}
    },
    computed: {
		layoutContainerClass() {
            return [
				'layout-wrapper',
				{
					'layout-static': this.menuMode === 'static',
					'layout-overlay': this.menuMode === 'overlay',
					'layout-overlay-active': this.overlayMenuActive,
					'layout-slim': this.menuMode === 'slim',
					'layout-horizontal': this.menuMode === 'horizontal',
					'layout-active': this.menuActive,
					'layout-mobile-active': this.staticMenuMobileActive,
					'layout-sidebar': this.menuMode === 'sidebar',
					'layout-sidebar-static': this.menuMode === 'sidebar' && this.sidebarStatic,
					'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}
			];
        },
		role_user(){
			return this.$store.state.user_role
		},
		club_id_admin(){
            return this.$store.state.club_id_admin
        },
        tanggal_view(){
            return this.tanggal ? this.$moment(this.tanggal).format("MMMM, DD YYYY") : this.$moment(new Date()).format("MMMM, DD YYYY")
        }
    },
    components: {
        AppTopBar,
		AppMenu,
        // AppRightMenu,
        AppFooter,
        AppBreadcrumb,
		AppConfig
    }
}
</script>

<style>
.absolute{
    z-index:1 !important
    
}
.custom-toast {
  display: flex;
  align-items: center;
}
.toast-content h4 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
}
.toast-content p {
  margin: 0;
  font-size: 0.875rem;
}
</style>
